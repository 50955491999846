<template>
  <v-card>
    <v-card-title>退店記録</v-card-title>
    <v-card-text v-if="opening">
      <v-row justify="center">
        <v-col class="text-center" cols="auto">
          時刻は
          <font color="orange" size="7">{{ currentTime }}</font
          >です。 <br />お疲れ様です!
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="auto">アイコンをクリックしてください。</v-col>
      </v-row>
      <sentiment-picker @save="onSentimentPickerSave"></sentiment-picker>
    </v-card-text>
    <v-card-text v-else>
      <sentiment-details
        :index="index"
        :fieldNames="fieldNames"
        @indexChanged="onIndexChanged"
        @save="onSentimentDetailsSave"
      ></sentiment-details>
    </v-card-text>
  </v-card>
</template>

<script>
import format from 'date-fns/format'
import ja from 'date-fns/locale/ja'

import { mapState, mapGetters } from 'vuex'

import {
  createStaffIfRequired,
  addSentiment,
  updateSentimentTogetherWithComments
} from '@/helpers/firebase-functions'

import sentimentPicker from '@/components/staff/sentimentPicker'
import sentimentDetails from '@/components/staff/sentimentDetails'
import { EVENT_NAMES, analyticsEvent } from '@/helpers/analytics'

export default {
  components: {
    sentimentPicker,
    sentimentDetails
  },
  data: () => ({
    opening: true,
    now: null,
    ownerId: '',
    ownerScenarioId: '',
    staffId: '',
    index: -1,
    fieldNames: [
      '自分自身について',
      'お客様について',
      'サービス・製品について',
      '上司・同僚・部下について',
      '制度について',
      '設備について'
    ]
  }),
  computed: {
    currentDate () {
      return format(this.now, 'yyyy/MM/dd', { locale: ja })
    },
    currentTime () {
      return format(this.now, 'HH:mm', { locale: ja })
    },
    ...mapState('auth', ['providerData', 'uid']),
    ...mapGetters('auth', ['profile']),
    telno () {
      return this.providerData[0].phoneNumber
    }
  },
  methods: {
    onIndexChanged (index) {
      this.index = index
    },
    async onSentimentPickerSave (sentimentIndex) {
      await addSentiment({
        ownerId: this.ownerId,
        ownerScenarioId: this.ownerScenarioId,
        staffId: this.uid,
        data: { in: false, index: sentimentIndex }
      })
      this.index = sentimentIndex
      this.opening = false
    },
    async onSentimentDetailsSave (data) {
      const { index, fields } = data
      await updateSentimentTogetherWithComments({
        ownerId: this.ownerId,
        ownerScenarioId: this.ownerScenarioId,
        staffId: this.uid,
        data: { index, comments: fields }
      })
      this.$router.replace(`/staff-sentiment/${this.ownerId}`)
    }
  },
  async created () {
    this.now = new Date()
    this.ownerId = this.$route.params.ownerId
    this.ownerScenarioId = process.env.VUE_APP_RECRUIT_SCENARIO_ID
    this.staffId = this.uid
    await createStaffIfRequired({
      ownerId: this.ownerId,
      ownerScenarioId: this.ownerScenarioId,
      staffId: this.staffId,
      data: { name: this.profile.givenName.concat(this.profile.firstName), telno: this.telno }
    })
  },
  mounted () {
    analyticsEvent(EVENT_NAMES.LOADED_STAFF_EXIT)
  }
}
</script>
